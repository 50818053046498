.white-vector-grower {
    width: 100%;
    display: none;
    z-index: 2;
    @include media-breakpoint-up(xl) {
        display: block;
        z-index: 2;

    }
}

.ApplyNowGrowerElement {
    min-height: 1400px;
    background-image: url(../../images/contact-grower-bg.webp);
    background-size: 100% 100%;
    padding-top: 100px;
    width: 100%;

    .apply-header {
        color: white;
        font-size: 50px;
        @include media-breakpoint-up(md) {
            font-size: 80px;
        }
    }

    .ContactTitleContent {
        align-content: center;
        text-align: center;
        justify-content: center;
        z-index: 2;
        margin-top: 120px;
        margin-bottom: 60px;

        @include media-breakpoint-up(md) {
        h1 {
            color: #1B1C47;
            text-align: center;
            font-size: 80px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
        }
        p {
            font-size: 20px;
        }
    }
        .secondary-content {
            color: #FF5700;
        }
        #send-msg-btn {
            padding: 15px 30px;
            border-radius: 25px;
            background-color: #FF5700;
            color: #fff;
            transform: translateY(40px);
            transition: transform .5s .3s, opacity .5s .3s;
            border: none;

        }
    }

    .pre-approve-wrap {

        &.hide {
            display:none;
        }

        ol {
            margin:0 auto 30px;
            max-width:920px;
            li {
                font-size: 18px;
                line-height: 24px;
                padding-bottom: 12px;
            }

            @include media-breakpoint-up(lg) {
                padding:0 100px;
            }
        }

        label {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: #0e255a;
            margin:10px 0px 0px;
            transform: translateY(-5px);
            padding-left: 8px;
        }

        input {
            width:20px;
            height:20px;
        }
    }

    input[type="checkbox"] {
        position:relative;
        appearance:none !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        width:22px !important;
        height:22px !important;
        border: 2px solid #FF5700 !important;
        border-radius: 5px !important;
        padding:0px !important;

        &:checked {
            background-color:#FF5700 !important;
        }
        &:checked:before {
            content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNDM4LjYgMTA1LjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTI1NiAyNTZjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwbC0xMjgtMTI4Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwTDE2MCAzMzguNyAzOTMuNCAxMDUuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMHoiLz48L3N2Zz4=");
            position: absolute;
            width: 15px;
            left: 1px;
        }

        &:focus {
            outline:none;
        }
    }

    #farm-address-wrap {
        width: 100%;
        grid-template-columns: 1fr 1fr;
        display: grid;
        gap: 21px;
        grid-column: 1 / span 2;

        &.hide {
            display:none;
        }
    }
    #secure-loan-wrap {
        transform:translateY(-20px);
        &.hide {
            display:none;
        }
    }

    .form-wrap {
        position: relative;
        box-shadow: 0px 40px 120px 0px rgba(27, 28, 71, 0.20);
        padding: 22px;
        z-index: 2;
        background-color: #FFF;
        max-width: 1000px;
        margin: 20px auto 0;
        border-radius: 20px;

        @include media-breakpoint-up(md) {
            padding: 50px;
        }
    }
    #apply-now-form {
        display: grid;
        column-gap: 30px;
        row-gap: 12px;
        grid-template-columns: 1fr 1fr;

        &.hide {
            display:none;
        }

        @include media-breakpoint-up(md) {
            row-gap: 20px;
        }

        h3 {
            grid-column: 1 / 3;
            color:#FF5700;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }


        @media screen and (max-width: 614px) {
            margin: 0 25px;
        }

        // .one-third {
        //     grid-column: 1 / 3;
        // }

        .full-width {
            grid-column: 1/span 2;
        }

        &.locked {
            &::before {
                pointer-events: all;
                background-color: #EFEFEF;
            }
        }

        &.success {
            &::before {
                background-color: #fcf7ed;
            }

            .success {
                pointer-events: all;

                .success-image, .success-heading, .success-message {
                    opacity: 1;
                    transform: none;
                }
            }
        }

        &::before {
            position: absolute;
            pointer-events: none;
            z-index: 1;
            border-radius: 10px;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transition: background-color .3s;
            content: "";
        }

        .location-city, textarea {margin-top:10px;}

        & input, .location-city, select, textarea {
            border: none;
            background-color: #EFEFEF;
            padding: 16px 0 15px 24px;
            width: 100%;
            box-sizing: border-box;
            line-height: 16px;
            font-size: 14px;
            font-family: filson-pro, sans-serif;
            border-radius: 8px;
            color: #0e255a;
            transition: box-shadow 0.2s, transform .2s;

            @include media-breakpoint-up(md) {
                font-size: 16px;
            }

            @include media-breakpoint-down(md) {
                grid-column: 1 / span 2;
            }

            &::placeholder {
                color: rgba(14, 37, 90, 0.4);
            }

            &:focus {
                outline: none;
                transform: translate(-6px, -6px);
                box-shadow: 6px 6px 0 0 #EFEFEF;

            }
        }

        & > input:not([name="newsletter"]), & > textarea {
            //grid-column: 1 / span 2; // This will make all inputs (except newsletter) and textarea span two columns
        }

        input[name="address"], input[name="address2"], input[name="farm-address"], input[name="farm-address2"] {
            grid-column: 1 / span 2;
        }

        .topics, .referral {
            grid-column: 1 / 3;
            display: flex;
            flex-wrap: wrap;

            .label {
                font-size: 16px;
                line-height: 20px;
                flex-basis: 100%;
                color: #0e255a;
                margin: 0 0 14px 0;
            }

            label {
                display: inline-flex;
                position: relative;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;

                input {
                    appearance: none;
                    position: absolute;
                    margin: 0;

                    &:focus + span {
                        outline: 2px solid EFEFEF;
                        background-color: #FF5700;
                    }

                    &:checked + span {
                        background-color: #FF5700;
                        color: #fff;
                    }
                }

                span {
                    display: inline-block;
                    user-select: none;
                    padding: 10px 18px 10px 17px;
                    font-size: 12px;
                    line-height: 20px;
                    color: #1B1C47;
                    border: solid 1px #EFEFEF;
                    background-color: #EFEFEF;
                    border-radius: 25px;
                    transition: background-color 0.1s, color 0.1s;

                    @include media-breakpoint-up(md) {
                        font-size: 16px;

                        padding: 15px 23px 15px 23px;
                        line-height: 20px;
                    }
                }
            }
        }
        .current-facility-questions, .applicant-questions {
            grid-column: 1 / 3;
            display: flex;
            flex-wrap: wrap;
            .label {
                font-size: 16px;
                line-height: 20px;
                flex-basis: 100%;
                color: #0e255a;
                margin: 0 0 14px 0;
            }

            label {
                display: inline-flex;
                position: relative;
                cursor: pointer;

                input {
                    appearance: none;
                    position: absolute;
                    margin: 0;

                    &:focus + span {
                        outline: 2px solid EFEFEF;
                        background-color: #FF5700;
                    }

                    // &:checked + span {
                    //     background-color: #FF5700;
                    //     color: #fff;
                    // }
                }

                span {
                    display: inline-block;
                    user-select: none;
                    padding: 10px 18px 10px 17px;
                    width: 100px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 16px;
                    color: #1B1C47;
                    border: solid 1px #EFEFEF;
                    background-color: #EFEFEF;
                    border-radius: 25px;
                    transition: background-color 0.1s, color 0.1s;

                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                        width: 150px;
                        padding: 15px 23px 15px 23px;
                        line-height: 20px;
                    }
                }
            }
        }

        .newsletter {
            grid-column: 1 / 3;
            display: flex;
            align-items: center;



            span {
                font-size: 12px;
                margin-left: 12px;
                line-height: 1em;
                color: #0e255a;
                @include media-breakpoint-up(md) {
                    font-size: 16px;
                }
            }
        }

        .recaptcha {
            display: flex;
            grid-column: 1 / 3;
            justify-content: center;

            span {
                position: relative;
            }
        }

        #apply-submit-btn {
            grid-column: 1 / 3;
            //@include button.button();
            display: flex;
            width: 50%;
            height: 50px;
            padding: 14px 15px 10px 15px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            @include media-breakpoint-up(md) {
                font-size: unset;
            }
        }

        .hidden {
            display: none;
        }

        .success {
            position: absolute;
            text-align: center;
            pointer-events: none;
            display: none;
            z-index: 1;
            background: #FFF;
            box-shadow: 0px 40px 120px 0px rgba(27, 28, 71, 0.20);
            background-image: url('../../images/Vector-success.svg');
            background-repeat: no-repeat;
            width: auto;
            height: 647px;
            flex-shrink: 0;
            border-radius: 20px;
            @include media-breakpoint-up(md) {
                width: 755px;
            }



            .success-image {
                position: relative;
                opacity: 0;
                transition: opacity .3s .3s;
                margin-top: 50px;

                // &::before {
                // 	position: absolute;
                // 	width: 100%;
                // 	height: 100%;
                // 	top: 0;
                // 	left: 0;
                // 	background-color: #faf1e0;
                // 	mask-position: center;
                // 	mask-repeat: no-repeat;
                // 	mask-size: contain;
                // 	mask-image: url(/_resources/themes/happy-egg/assets/blob-2.svg);
                // 	content: "";
                // }

                svg {
                    position: relative;
                    height: 100%;
                    width: 100%;
                }
                .success-heading {
                    margin: 14px 0;
                    font-size: 50px;
                    font-weight: 800;
                    line-height: normal;
                    color: #FF5700;
                    //opacity: 0;
                    transform: translateY(40px);
                    transition: transform .5s .3s, opacity .5s .3s;

                    @media screen and (max-width: 500px) {
                        font-size: 16vw;
                    }
                }
                .success-message {
                    font-family: filson-pro, sans-serif;
                    margin: 0;
                    font-size: 24px;
                    line-height: 35ox;
                    color: #0e255a;
                    //opacity: 0;
                    transform: translateY(40px);
                    transition: transform .7s .3s, opacity .7s .3s;
                }
                #FAQ-btn {
                    margin-top: 80px;
                    padding: 15px 0;
                    border-radius: 25px;
                    background-color: #FF5700;
                    color: #fff;
                    width: 40%;
                    transform: translateY(40px);
                    transition: transform .5s .3s, opacity .5s .3s;
                    border: none;
                }
                .accent-egg-success {
                    position: absolute;
                    bottom: -50px;
                    left: -25px;
                    max-width: 140px;
                }
                .falling-egg-success {
                    position: absolute;
                    top: 0px;
                    right: -50px;
                    transform: rotate(5.607deg);

                }

            }


        }

        .error {
            grid-column: 1 / 3;
            text-align: center;
            font-size: 20px;
            line-height: 24px;
            margin: 0;
            color: red;
        }

        .applicant-questions {
            margin-top:5px;
            select, textarea, input {
                margin-bottom:20px;
                margin-top:0px;
            }
            .label {
                margin-bottom:10px;
            }
        }

        .referral-error-message {
            display:none;
            background-color:red;
            color:#fff;
            padding:5px 10px;
            &.show {
                display:block;
            }
        }

        // .checkbox-wrap {
        //     width:100%;
        //     display:block;
        //     &:nth-child(8) {
        //         margin-bottom:15px;
        //     }

        //     label {
        //         color: #1B1C47;
        //     }
    
        //     input[type="checkbox"] {
        //         position:relative;
        //         appearance:auto !important;
        //         -webkit-appearance: auto !important;
        //         -moz-appearance: auto !important;
        //         width:15px !important;
        //         margin-right:7px;
        //         &:focus {transform:none;}
        //     }
        // }
    
    }

}
