.page-heading {
    position: relative;
    display:flex;
    height:100vh;
    width:100%;
    // max-height:800px;
    background-size:cover;
    background-position: center;
    justify-content:center;
    align-items:center;
    text-align: center;
    overflow-x:clip;

    @include media-breakpoint-up(md) {
        height:700px;
        background-position: unset;
    }
    @include media-breakpoint-up(lg) {
        height:700px;
    }


    &.article-heading {
        &:after {
            content:'';
            position:absolute;
            width:100%;
            height:100%;
            background:rgba(0,0,0,.3);
        }
    }


    .mask {
        position:absolute;
        bottom:-1px;
        left:0px;
        width:100%;
        z-index:10;
    }

    &.Short {
        height:300px;
        @include media-breakpoint-up(md) {
            height:500px;
        }
        @include media-breakpoint-up(lg) {
            height:550px;
        }
    }
    &.Tall {
        @include media-breakpoint-up(md) {
            height:600px;
        }
        @include media-breakpoint-up(lg) {
            height:750px;
        }
    }

    .page-heading-content {
        position: relative;
        z-index: 21;
        width:100%;
    }

    video {
        position:absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        object-fit:cover;
        z-index:1;
    }


    h1 {
        color: #FFF;
        font-size: 80px;
        line-height:82px;
        font-style: normal;
        font-weight: 800;

        @include media-breakpoint-up(md) {
            font-size: 80px;
            line-height:88px;
        }
        @include media-breakpoint-up(lg) {
            // font-size: 120px;
        }

    }

    p {
        font-size:24px;
        color:$white;
    }


    .product-subtitle {
        font-size:40px;
        line-height:44px;
        @include media-breakpoint-up(md) {
            font-size:60px;
            line-height:64px;
        }
    }

    .article-heading-inner {
        max-width:900px;
        text-align:left;
        color:#ffffff;
        position:relative;
        z-index:5;
        padding:0 20px;

        h1 {
            color: #FFF;
            font-size: 50px;
            line-height:58px;
            @include media-breakpoint-up(md) {
                font-size: 60px;
                line-height:68px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 80px;
                line-height:88px;
            }

        }

        .heading-small {
            font-size:40px;
            line-height:44px;
            @include media-breakpoint-up(md) {
                font-size: 60px;
                line-height:68px;
            }
        }

        .subtitle {
            font-size:24px;
        }
    }

    .btn-back {
        display:none;
        position:absolute;
        left:-100px;
        top:10px;
        z-index:5;
        @include media-breakpoint-up(xl) {
            display:block;
        }

    }

    // SVG Text on Path (Animated)
    #hero-text-container text {
        font-size: 100px;
        color: #fff;
        font-weight: 800;

        #hero-text-path {fill:#fff;}
        #hero-text-curve {transform:translateY(20px);}
    }

}
